<template>
  <div>
    <b-sidebar
      id="sidebar-server-handler"
      sidebar-class="sidebar-lg"
      :visible="isServerHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-server-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="!serverLocal.id"
            class="mb-0"
          >
            Add Server
          </h5>
          <h5
            v-else
            class="mb-0"
          >
            Update Server {{ serverLocal.name }}
          </h5>
          <div>
            <feather-icon
              v-show="serverLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-server', serverLocal); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Main IP -->
            <validation-provider
              #default="validationContext"
              name="Main IP"
              :rules="{regex: ipRegexPattern}"
            >
              <b-form-group
                label="Main IP"
                label-for="server-main-ip"
              >
                <b-form-input
                  v-model="serverLocal.mainIp"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  input-id="server-main-ip"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Server Name -->
            <validation-provider
              #default="validationContext"
              name="Server Name"
              rules="max:200"
            >
              <b-form-group
                label="Server Name"
                label-for="server-subnet-name"
              >
                <b-form-input
                  id="server-subnet-name"
                  v-model="serverLocal.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Server Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Server Comment -->
            <validation-provider
              #default="validationContext"
              name="Server comment"
              rules="max:230"
            >
              <b-form-group
                label="Server comment"
                label-for="server-subnet-description"
              >
                <b-form-input
                  id="server-subnet-description"
                  v-model="serverLocal.comment"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Server comment"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Location -->
            <validation-provider
              #default="validationContext"
              name="Location"
              rules="max:230"
            >
              <b-form-group
                label="Location"
                label-for="server-location"
              >
                <b-form-input
                  id="server-vlan-location"
                  v-model="serverLocal.location"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Location"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- City -->
            <validation-provider
              #default="validationContext"
              name="City"
              rules="max:200"
            >
              <b-form-group
                label="City"
                label-for="server-city"
              >
                <b-form-input
                  id="server-city"
                  v-model="serverLocal.city"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="City"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Country -->
            <b-form-group
              label="Country"
              label-for="server-country"
            >
              <v-select
                v-model="serverLocal.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countriesList()"
                :reduce="option => option.value"
                input-id="server-country"
              />
            </b-form-group>

            <!-- Server Type -->
            <validation-provider
              #default="validationContext"
              name="Server Type"
              rules="required"
            >
              <b-form-group
                label="Server Type"
                label-for="server-type"
              >
                <v-select
                  v-model="serverLocal.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="serverTypesList"
                  input-id="server-usage-type"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- isRented -->
            <b-form-group
              label="A rented server?"
            >
              <b-form-checkbox
                v-model="serverLocal.isRented"
                checked="false"
                class="custom-control-primary"
                name="check-button"
                switch
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>

            <!-- Provider -->
            <b-form-group
              v-if="serverLocal.isRented"
              label="Provider"
              label-for="server-provider"
            >
              <v-select
                v-model="serverLocal.providerId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="providerList()"
                :reduce="option => option.value"
                input-id="server-provider"
              />
            </b-form-group>

            <!-- inSublease -->
            <b-form-group
              label="A rented server?"
            >
              <b-form-checkbox
                v-model="serverLocal.inSublease"
                checked="false"
                class="custom-control-primary"
                name="check-button"
                switch
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>

            <!-- Client -->
            <b-form-group
              v-if="serverLocal.inSublease"
              label="Client"
              label-for="server-client"
            >
              <v-select
                v-model="serverLocal.clientId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clientList()"
                :reduce="option => option.value"
                input-id="server-client"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ serverLocal.id ? 'Update' : 'Add ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
          </b-form>
        </validation-observer>

        <!-- Body Networks -->
        <validation-observer
          v-if="serverLocal.id"
          #default="{ handleSubmit }"
          ref="refFormObserverUpdateIpV4s"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmitUpdateIpV4s)"
          >
            <!-- Networks -->
            <b-form-group
              label="Networks"
              label-for="server-ipv4s"
            >
              <v-select
                v-model="serverLocal.ipV4s"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="networkList"
                input-id="server-provider"
                :reduce="(option) => option.id"
                multiple
                @option:deselected="deselectNetwork"
              />
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Update Networks
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  email, length, required, url,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import { integer, max, min } from '@/libs/custom-validations'
import useServerHandler from './useServerHandler'
import { ipRegex } from '@/libs/regex-patterns'
import { serverTypes } from '@/libs/server-types'
import store from '@/store'
import { getAllClientAPI, getAllProviderAPI } from '@/libs/api'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,

    // 3rd packages
    vSelect,

    // Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isServerHandlerSidebarActive',
    event: 'update:is-server-handler-sidebar-active',
  },
  props: {
    isServerHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    server: {
      type: Object,
      required: true,
    },
    clearServerData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      length,
      max,
      min,
      integer,
      networkList: {
        type: Array,
        default: () => [],
      },
    }
  },
  beforeMount() {
    getAllProviderAPI()
      .then(response => {
        if (response.status === 200) {
          // eslint-disable-next-line no-underscore-dangle
          if (response.data._embedded !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            const { providerBaseDtoes } = response.data._embedded
            store.commit('ipamProvider/SET_PROVIDER_LIST', providerBaseDtoes)
          }
        }
      })

    getAllClientAPI()
      .then(response => {
        if (response.status === 200) {
          // eslint-disable-next-line no-underscore-dangle
          if (response.data._embedded !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            const { clientBaseDtoes } = response.data._embedded
            store.commit('billingClient/SET_CLIENT_LIST', clientBaseDtoes)
          }
        }
      })
    this.networkList = this.getNetworkList()
  },
  methods: {
    deselectNetwork(deselectNet) {
      const ip = store.getters['ipamIpV4/getIpV4ById'](deselectNet.id)[0]
      if (ip) {
        ip.mainIp = null
        store.commit('ipamIpV4/UPDATE_ELEMENT', ip)
        this.networkList = this.getNetworkList()
      }
    },
  },
  setup(props, { emit }) {
    const {
      serverLocal,
      resetServerLocal,

      // UI
      assigneeOptions,
      onSubmit,
      onSubmitUpdateIpV4s,
      tagOptions,
      countriesList,
      countryFindByName,
      providerList,
      clientList,
      mainIpList,
      serverFindByIP,
      subnetMaskList,
      getNetworkList,
    } = useServerHandler(toRefs(props), emit)

    if (serverLocal.id) {
      serverLocal.country = countryFindByName(serverLocal.country)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetServerLocal, props.clearServerData)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Enter subnet description',
    }

    const ipRegexPattern = ipRegex
    const serverTypesList = serverTypes

    return {
      // Add New
      serverLocal,
      onSubmit,
      onSubmitUpdateIpV4s,
      assigneeOptions,
      tagOptions,
      // usageTypes,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // Pattern validation
      ipRegexPattern,

      serverTypesList,

      // UI
      editorOption,
      //  Select
      countriesList,
      clientList,
      mainIpList,
      serverFindByIP,
      subnetMaskList,
      providerList,
      getNetworkList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
