import { ref } from '@vue/composition-api'
import { getAllNetworkAPI } from '@/libs/api'
import store from '@/store'

export default function useDashboardIpamServerHandler() {
  // Server Right Sidebar Action
  const blankServer = {
    id: null,
    mainIp: null,
    location: null,
    name: null,
    providerId: null,
    providerName: null,
    status: null,
    type: null,
    ipV4s: [],
    ipV4sIds: [],
    inSublease: false,
    children: false,
    isRented: false,
    city: null,
    clientId: null,
    clientName: null,
    comment: null,
    country: null,
    childServers: [],
  }
  const server = ref(JSON.parse(JSON.stringify(blankServer)))
  const clearServerData = () => {
    server.value = JSON.parse(JSON.stringify(blankServer))
  }

  const isIpActivityHandlerSidebarActive = ref(false)
  let indexLastClickedRowSubD = null
  const handleServerClick = (item, index) => {
    getAllNetworkAPI()
      .then(response => {
        if (response.status === 200) {
          // eslint-disable-next-line no-underscore-dangle
          if (response.data._embedded !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            const { ipV4DetailDtoes } = response.data._embedded
            store.commit('ipamIpV4/SET_IPV4_LIST', ipV4DetailDtoes)

            indexLastClickedRowSubD = index
            server.value = item
            const ips = []
            server.value.ipV4s.forEach(ip => {
              ips.push({ label: `${ip.subnetAddress}/${ip.subnetMask}`, id: ip.id })
            })
            server.value.ipV4s = ips
            isIpActivityHandlerSidebarActive.value = true
          }
        }
      })
  }

  const tableFields = [
    { key: 'id', label: 'Id' },
    { key: 'mainIp', label: 'Main IP', sortable: true },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'location', label: 'Location', sortable: true },
    { key: 'city', label: 'City', sortable: true },
    { key: 'country', label: 'Country', sortable: true },
    { key: 'type', label: 'Type', sortable: true },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'providerName', label: 'Provider', sortable: true },
    { key: 'clientName', label: 'Client', sortable: true },
    { key: 'isRented', label: 'Is Rented', sortable: true },
    { key: 'inSublease', label: 'In Sublease', sortable: true },
    { key: 'children', label: 'Is Children', sortable: true },
  ]

  return {
    // Server Right Sidebar Action
    blankServer,
    server,
    clearServerData,
    isIpActivityHandlerSidebarActive,
    handleServerClick,
    // Server Detail
    indexLastClickedRowSubD,
    tableFields,
  }
}
