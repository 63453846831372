<template>
  <section id="dashboard-analytics">
    <b-container>
      <b-row>
        <b-col cols="8" />
        <b-col
          cols="4"
          class="d-flex justify-content-end mb-2"
        >
          <b-button-group>
            <b-dropdown
              right
              variant="outline-primary"
              text="Add"
            >
              <b-dropdown-item @click="isIpActivityHandlerSidebarActive = true">
                Add Server Server
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              right
              variant="outline-primary"
              text="Settings"
            >
              <b-dropdown-item>Coming soon</b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-card
            border-variant="success"
          >
            <b-tabs
              pills
              nav-class="mb-3"
            >
              <b-tab
                title="Server Details"
                active
              >
                <server-detail-handler
                  :server-list="activeListServer"
                  :field-description="tableFields"
                  @click-row="handleServerClick"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <!-- Server Handler -->
      <server-handler-sidebar
        v-model="isIpActivityHandlerSidebarActive"
        :server="server"
        :clear-server-data="clearServerData"
        @remove-server="deleteServer"
        @add-server="createServer"
        @update-server="editServer"
        @update-server-networks="updateServerNetworks"
      />
    </b-container>
  </section>
</template>

<script>
import {
  BButtonGroup, BCard, BCol, BContainer, BDropdown, BDropdownItem, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { toRefs } from '@vue/composition-api'
import store from '@/store'
import ServerHandlerSidebar from '@/views/ipam/server/ServerHandlerSidebar'
import ServerDetailHandler from '@/views/ipam/server/ServerDetailHandler'
import useDashboardIpamServerHandler from '@/views/ipam/server/useDashboardIpamServerHandler'
import {
  createServerApi,
  deleteServerApi,
  editServerByIdApi,
  getAllNetworkAPI,
  getAllServerAPI,
  updateServerNetworksByIdApi,
} from '@/libs/api'

export default {
  components: {
    ServerDetailHandler,
    BRow,
    BCol,
    BContainer,
    BCard,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    ServerHandlerSidebar,
  },
  data() {
    return {
      data: {},
      isLoadIp: false,
      isReloadTree: false,
      activeListServer: [],
    }
  },
  beforeMount() {
    this.getAllServer()
  },
  methods: {
    kFormatter,
    errorShow(error) {
      console.log('error', error)
      console.log('msg', error.message)
      this.showToast('danger', error.toString(), 'Error')
    },
    createServer(server) {
      if (server === undefined || server === null) {
        return
      }
      createServerApi(server).then(response => {
        if (response.status === 201) {
          store.commit('ipamServers/PUSH_SERVER', response.data)
        } else {
          this.showToast('danger', `${response.data.code}: ${response.data.message}`, 'Error')
        }
      }).catch(error => {
        this.errorShow(error)
      }).finally(() => {
        this.getAllServer()
      })
    },
    editServer(server) {
      if (server === undefined || server === null) {
        return
      }
      editServerByIdApi(server).then(response => {
        if (response.status === 201) {
          store.commit('ipamServers/PUT_SERVER_LIST', response.data)
        } else {
          this.errorShow(`${response.data.code}: ${response.data.message}`)
        }
      }).catch(error => {
        this.errorShow(error)
      }).finally(() => {
        this.getAllServer()
      })
    },
    updateServerNetworks(server) {
      if (server === undefined || server === null) {
        return
      }
      updateServerNetworksByIdApi(server.id, server.ipV4s).then(response => {
        if (response.status === 201) {
          store.commit('ipamServers/PUT_SERVER_LIST', response.data)
        } else {
          this.errorShow(`${response.data.code}: ${response.data.message}`)
        }
      }).catch(error => {
        this.errorShow(error)
      }).finally(() => {
        this.getAllServer()
      })
    },
    deleteServer(server) {
      if (server === undefined || server === null) {
        return
      }
      deleteServerApi(server).then(response => {
        if (response.status === 200) {
          store.commit('ipamServers/DELETE_SERVER_BY_ID', response.data.id)
        } else {
          this.errorShow(`${response.data.code}: ${response.data.message}`)
        }
      }).catch(error => {
        this.errorShow(error)
      }).finally(() => {
        this.getAllServer()
      })
    },
    getAllServer() {
      getAllServerAPI()
        .then(response => {
          if (response.status === 200) {
            // eslint-disable-next-line no-underscore-dangle
            if (response.data._embedded !== undefined) {
              // eslint-disable-next-line no-underscore-dangle
              const { serverDetailDtoes } = response.data._embedded
              store.commit('ipamServers/SET_SERVER_LIST', serverDetailDtoes)
            }
          }
        }).finally(() => {
          this.activeListServer = store.getters['ipamServers/getServerList']
        })
    },
    showToast(variant, text, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
  },
  setup(props, { emit }) {
    const {
      // Server Right Sidebar Action
      server,
      clearServerData,
      isIpActivityHandlerSidebarActive,
      // Server Detail
      indexLastClickedRowSubD,
      tableFields,
      handleServerClick,
    } = useDashboardIpamServerHandler(toRefs(props), emit)

    getAllNetworkAPI()
      .then(response => {
        if (response.status === 200) {
          // eslint-disable-next-line no-underscore-dangle
          if (response.data._embedded !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            const { ipV4DetailDtoes } = response.data._embedded
            store.commit('ipamIpV4/SET_IPV4_LIST', ipV4DetailDtoes)
          }
        }
      })

    return {
      server,
      clearServerData,
      isIpActivityHandlerSidebarActive,
      handleServerClick,
      // Server Detail Table
      tableFields,
      //  Server Detail Action
      indexLastClickedRowSubD,
    }
  },
}
</script>
