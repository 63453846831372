import { ref, watch } from '@vue/composition-api'
import countryLib from 'country-list-js'
import store from '@/store'

export default function useServerHandler(props, emit) {
  // ------------------------------------------------
  // serverLocal
  // ------------------------------------------------
  const serverLocal = ref(JSON.parse(JSON.stringify(props.server.value)))
  const resetServerLocal = () => {
    serverLocal.value = JSON.parse(JSON.stringify(props.server.value))
  }
  watch(props.server, () => {
    resetServerLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const serverData = JSON.parse(JSON.stringify(serverLocal))

    if (!serverData.value.isRented) {
      serverData.value.providerId = null
      serverData.value.providerName = null
    }

    if (!serverData.value.inSublease) {
      serverData.value.clientId = null
      serverData.value.clientName = null
    }

    // * If event has id => Edit Event
    // Emit event for add/update event

    if (props.server.value.id) emit('update-server', serverData.value)
    else emit('add-server', serverData.value)

    // Close sidebar
    emit('update:is-server-handler-sidebar-active', false)
  }

  const onSubmitUpdateIpV4s = () => {
    const serverData = JSON.parse(JSON.stringify(serverLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event

    if (props.server.value.id) emit('update-server-networks', serverData.value)

    // Close sidebar
    emit('update:is-server-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const countriesList = () => {
    const countriesListM = []
    Object.values(countryLib.all).forEach(c => {
      countriesListM.push({ label: `${c.name} - ${c.iso2}`, value: c.name })
    })
    return countriesListM
  }

  const countryFindByName = cName => {
    const filter = countryLib.all.filter(c => c.name === cName)
    if (filter !== undefined) {
      return filter.name
    }
    return null
  }

  const serverFindByIP = ip => {
    const server = store.getters['ipamServers/getServerByStringMainIP'](ip)
    if (server) {
      return server
    }
    return null
  }
  /*
    const serverList = () => {
      const servers = []
      store.getters['ipamServers/getServerList'].forEach(g => {
        servers.push({ label: `${g.name}`, value: g.id })
      })
      return servers
    } */

  const providerList = () => {
    const providers = []
    store.getters['ipamProvider/getProviderAllList'].forEach(s => {
      providers.push({ label: `${s.name}`, value: s.id })
    })
    return providers
  }

  const clientList = () => {
    const clients = []
    store.getters['billingClient/getClientAllList'].forEach(g => {
      clients.push({ label: `${g.name}`, value: g.id })
    })
    return clients
  }

  const getNetworkList = () => {
    const list = []
    store.getters['ipamIpV4/getIpV4AllList'].forEach(v => {
      if (v.mainIp === null) {
        console.log('v')
        console.log(v)
        list.push({ label: `${v.subnetAddress}/${v.subnetMask}`, id: v.id })
      }
    })
    console.log('list.concat(serverLocal.value.ipV4s)')
    console.log(serverLocal.value.ipV4s)
    console.log(list.concat(serverLocal.value.ipV4s))
    console.log(list)
    return list.concat(serverLocal.value.ipV4s)
  }
  /* const networksListM = []
    store.getters['ipamIpV4/getIpV4AllList'].forEach(c => {
      if (!c.mainIp){
        networksListM.push({ label: `${c.name}/${c.mainIp}`, value: c.id })
      }
    })
    return networksListM */

  return {
    serverLocal,
    resetServerLocal,

    // UI
    onSubmit,
    onSubmitUpdateIpV4s,

    //  Select list
    countriesList,
    countryFindByName,
    serverFindByIP,
    clientList,
    providerList,
    getNetworkList,
  }
}
