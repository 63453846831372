// import { axiosAdm as httpAdm } from '@/libs/axios'
// import { jwt } from '@/auth/jwt/useJwt'

import jwt from '@/auth/jwt/useJwt'

const apiUrl = 'https://ipcp.ipmen.network:8090'
// const apiUrl = 'https://ipcp.ipmen.network:8081'

const httpAdm = jwt.axiosIns
// Network
// eslint-disable-next-line import/prefer-default-export
export async function getNetworkByGroupSlugAPI(slug) {
  try {
    return httpAdm.get(`/ipam/ipv4/group-ip/${slug}`, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function getAllNetworkAPI() {
  try {
    return httpAdm.get('/ipam/ipv4/all', { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function createIPv4Api(ipV4) {
  try {
    return httpAdm.post('/ipam/ipv4/', ipV4, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function editIPv4Api(ipV4) {
  try {
    return httpAdm.put(`/ipam/ipv4/${ipV4.id}`, ipV4, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function deleteIPv4Api(ipV4) {
  try {
    return httpAdm.delete(`/ipam/ipv4/${ipV4.id}`, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

// GroupIp
export async function getAllGroupIpAPI() {
  try {
    return httpAdm.get('/ipam/group-ip/all', { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function createGroupIpApi(groupIP) {
  try {
    return httpAdm.post('/ipam/group-ip/', {
      comment: groupIP.comment,
      name: groupIP.name,
    }, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function editGroupIPbySlugApi(groupIP) {
  try {
    return httpAdm.put(`/ipam/group-ip/${groupIP.slug}`, {
      comment: groupIP.comment,
      name: groupIP.name,
    }, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function deleteGroupIPApi(slug) {
  try {
    return httpAdm.delete(`/ipam/group-ip/${slug}`, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

// Server
export async function getAllServerAPI() {
  try {
    return httpAdm.get('/ipam/server/all', { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function createServerApi(server) {
  try {
    return httpAdm.post('/ipam/server/', server, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function addProviderForServerApi(serverId, providerId) {
  try {
    return httpAdm.post(`/ipam/server/${serverId}/provider/${providerId}`, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function editServerByIdApi(server) {
  try {
    return httpAdm.put(`/ipam/server/${server.id}`, server, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function updateServerNetworksByIdApi(serverId, listNetworks) {
  try {
    return httpAdm.post(`/ipam/server/${serverId}/network`, { ids: listNetworks }, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function deleteServerApi(server) {
  try {
    return httpAdm.delete(`/ipam/server/${server.id}`, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

// Client
export async function getAllClientAPI() {
  try {
    return httpAdm.get('/billing/client/all', { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function getClientByIdtAPI(id) {
  try {
    return httpAdm.get(`/billing/client/${id}`, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function createClientApi(client) {
  try {
    return httpAdm.post('/billing/client', client, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function editClientApi(client) {
  try {
    return httpAdm.put(`/billing/client/${client.id}`, client, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function deleteClientApi(client) {
  try {
    return httpAdm.delete(`/billing/client/${client.id}`, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

// Provider
export async function getAllProviderAPI() {
  try {
    return httpAdm.get('/ipam/provider/all', { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function getProviderByIdAPI(id) {
  try {
    return httpAdm.get(`/ipam/provider/${id}`, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function createProviderApi(provider) {
  try {
    return httpAdm.post('/ipam/provider', provider, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function editProviderApi(provider) {
  try {
    return httpAdm.put(`/ipam/provider/${provider.id}`, provider, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}

export async function deleteProviderApi(provider) {
  try {
    return httpAdm.delete(`/ipam/provider/${provider.id}`, { baseURL: `${apiUrl}/adm/api/v1` })
  } catch (e) {
    throw new Error(e)
  }
}
